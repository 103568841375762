import React from "react";

function Linkedin() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 82 82"
    >
      <path
        fill="#D1D1D1"
        d="M72.238 0H9.762C4.373 0 0 4.373 0 9.762v62.476C0 77.627 4.373 82 9.762 82h62.476C77.627 82 82 77.627 82 72.238V9.762C82 4.373 77.627 0 72.238 0zM25.381 31.238v37.095H13.667V31.238H25.38zM13.667 20.441c0-2.733 2.342-4.822 5.857-4.822 3.514 0 5.72 2.09 5.857 4.822 0 2.734-2.187 4.94-5.857 4.94-3.515 0-5.857-2.206-5.857-4.94zm54.666 47.892H56.62V48.81c0-3.905-1.952-7.81-6.833-7.888h-.157c-4.724 0-6.677 4.022-6.677 7.888v19.523H31.238V31.238h11.714v4.998s3.769-4.998 11.344-4.998c7.75 0 14.037 5.33 14.037 16.127v20.968z"
      ></path>
    </svg>
  );
}

export default Linkedin;
