import React from "react";

function Instagram() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 82 82"
    >
      <path
        fill="#D1D1D1"
        d="M22.778 0C10.218 0 0 10.218 0 22.778v36.444C0 71.782 10.218 82 22.778 82h36.444C71.782 82 82 71.782 82 59.222V22.778C82 10.218 71.782 0 59.222 0H22.778zm0 9.111h36.444c7.535 0 13.667 6.132 13.667 13.667v36.444c0 7.535-6.132 13.667-13.667 13.667H22.778c-7.535 0-13.667-6.132-13.667-13.667V22.778c0-7.535 6.132-13.667 13.667-13.667zm41 4.556a4.556 4.556 0 100 9.11 4.556 4.556 0 000-9.11zM41 18.222C28.44 18.222 18.222 28.44 18.222 41c0 12.56 10.218 22.778 22.778 22.778 12.56 0 22.778-10.218 22.778-22.778 0-12.56-10.218-22.778-22.778-22.778zm0 9.111c7.535 0 13.667 6.132 13.667 13.667S48.535 54.667 41 54.667 27.333 48.535 27.333 41 33.465 27.333 41 27.333z"
      ></path>
    </svg>
  );
}

export default Instagram;
