import React from "react";

function Spring() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="120"
      fill="none"
      viewBox="0 0 282 254"
    >
      <path
        fill="#75ABFD"
        d="M276.987 108.345l-51.826-89.69C218.515 7.125 206.156 0 192.826 0H89.174c-13.33 0-25.689 7.126-32.335 18.654L5.013 108.346c-6.684 11.566-6.684 25.781 0 37.31l51.826 89.69C63.485 246.911 75.844 254 89.174 254h103.652c13.33 0 25.689-7.089 32.335-18.655l51.826-89.69c6.684-11.529 6.684-25.744 0-37.31zM196.56 178.3c-13.367 14.812-31.701 23.542-51.639 24.587a72.027 72.027 0 01-3.996.112c-18.482 0-36.069-6.716-49.921-19.177-30.618-27.534-33.12-74.842-5.526-105.473a71.754 71.754 0 016.832-6.641c4.668-4.03 11.725-3.507 15.795 1.194 4.032 4.663 3.472 11.752-1.233 15.782a50.895 50.895 0 00-4.742 4.663c-19.304 21.416-17.586 54.546 3.884 73.835 10.38 9.364 23.747 14.103 37.749 13.356 13.965-.746 26.809-6.864 36.144-17.236 19.304-21.416 17.549-54.546-3.883-73.835-4.593-4.141-4.966-11.23-.822-15.819 4.145-4.589 11.202-4.962 15.832-.82 30.617 27.533 33.082 74.841 5.526 105.472zM152.202 59.881v63.276c0 6.156-5.004 11.193-11.202 11.193-3.062 0-5.9-1.269-7.916-3.283a11.23 11.23 0 01-3.286-7.91V59.881c0-6.193 5.041-11.193 11.202-11.193 6.198 0 11.202 5 11.202 11.193z"
      ></path>
    </svg>
  );
}

export default Spring;
