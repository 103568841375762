import React from "react";

function GitHub() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 82 80"
    >
      <path
        fill="#fff"
        d="M28.15 80a3.566 3.566 0 002.154-3.276V67.09c0-.352.029-.717.073-1.088a1.066 1.066 0 01-.073.017h-6.417c-2.674 0-4.991-1.07-6.06-3.211-1.249-2.32-1.784-6.245-4.992-8.386-.535-.357-.179-.893.891-.893 1.07.179 3.387 1.606 4.813 3.569 1.604 1.963 3.209 3.569 6.061 3.569 4.433 0 6.81-.223 8.24-.99 1.664-2.48 3.97-4.363 6.377-4.363v-.045c-10.103-.325-16.558-3.686-19.564-8.877-6.533.075-12.221.723-15.467 1.262a38.455 38.455 0 01-.27-1.761c3.204-.529 8.634-1.155 14.876-1.274-.2-.493-.372-.998-.519-1.515-6.258-.318-11.66-.07-14.594.173-.035-.592-.083-1.183-.09-1.783 2.939-.24 8.194-.481 14.292-.198a17.562 17.562 0 01-.231-2.753c0-3.033 1.07-6.245 3.03-8.921-.891-3.033-2.14-9.457.357-11.776 4.813 0 8.2 2.32 9.804 3.747C33.87 20.34 37.257 19.627 41 19.627c3.743 0 7.13.714 9.983 1.963 1.604-1.428 4.99-3.747 9.804-3.747 2.674 2.498 1.248 8.921.357 11.776 1.96 2.676 3.03 5.71 2.852 8.922 0 .863-.08 1.696-.196 2.514 6.237-.307 11.635-.061 14.624.181-.004.602-.059 1.189-.09 1.783-2.98-.246-8.513-.5-14.902-.159-.158.6-.35 1.183-.579 1.749 6.321.082 11.881.694 15.238 1.23-.077.592-.166 1.179-.27 1.76-3.408-.546-9.217-1.184-15.827-1.217-2.968 5.136-9.305 8.485-19.211 8.875v.056c4.634 0 8.913 6.958 8.913 11.776v9.635c0 1.468.887 2.73 2.155 3.276C70.18 74.59 82 59.174 82 41.038 82 18.41 63.609 0 41 0S0 18.41 0 41.038C0 59.174 11.819 74.59 28.15 80z"
      ></path>
    </svg>
  );
}

export default GitHub;
