import React from "react";

function Email() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 83 56"
    >
      <path
        fill="#fff"
        d="M72.412 0H10.076C4.52 0 0 4.52 0 10.076v35.848C0 51.48 4.52 56 10.076 56h62.336c5.556 0 10.076-4.52 10.076-10.076V10.077C82.49 4.52 77.97 0 72.412 0zM67.49 6.573L41.244 23.482 15 6.573h52.49zm4.923 42.855H10.076a3.507 3.507 0 01-3.503-3.503V10.263l32.643 20.99c.044.028.091.047.136.072.047.026.095.052.144.076.253.13.513.235.78.304.027.008.054.011.082.018.292.069.588.111.884.111h.007c.295 0 .591-.041.884-.111.027-.007.054-.01.082-.018.266-.069.526-.174.78-.304.048-.024.096-.05.143-.076.045-.025.092-.044.136-.071l32.643-20.99v35.66a3.51 3.51 0 01-3.505 3.504z"
      ></path>
    </svg>
  );
}

export default Email;
