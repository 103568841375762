import React from "react";

function Download() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 54 55"
    >
      <path
        fill="#fff"
        d="M5.347 38.145a2.667 2.667 0 00-2.674-2.661A2.667 2.667 0 000 38.145h5.347zm48.653 0a2.667 2.667 0 00-2.673-2.661 2.667 2.667 0 00-2.673 2.661H54zm-29.108 1.912a2.654 2.654 0 00.464 3.735 2.682 2.682 0 003.752-.462l-4.216-3.273zm18.117-14.469a2.654 2.654 0 00-.464-3.735 2.682 2.682 0 00-3.752.462l4.216 3.273zM24.892 43.33a2.682 2.682 0 003.752.462 2.654 2.654 0 00.464-3.735l-4.216 3.273zm-9.685-21.015a2.682 2.682 0 00-3.752-.462 2.654 2.654 0 00-.464 3.735l4.216-3.273zm9.12 19.378A2.667 2.667 0 0027 44.355a2.667 2.667 0 002.673-2.661h-5.346zm5.346-39.032A2.667 2.667 0 0027 0a2.667 2.667 0 00-2.673 2.661h5.346zM0 38.145v3.548h5.347v-3.548H0zm0 3.548C0 48.98 5.801 55 13.099 55v-5.323c-4.218 0-7.752-3.51-7.752-7.983H0zM13.099 55h27.802v-5.323H13.099V55zm27.802 0C48.199 55 54 48.979 54 41.694h-5.346c0 4.473-3.535 7.983-7.753 7.983V55zM54 41.694v-3.549h-5.346v3.548H54zM29.108 43.33L43.009 25.59l-4.216-3.274-13.901 17.742 4.216 3.273zm0-3.273L15.207 22.315l-4.216 3.273L24.892 43.33l4.216-3.273zm.565 1.636V2.662h-5.346v39.032h5.346z"
      ></path>
    </svg>
  );
}

export default Download;
